import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="w-full bg-gray-900 text-gray-400 py-8">
      <div className="container mx-auto px-6 lg:px-12">
        {/* Logo and Main Info */}
        <div className="flex flex-col lg:flex-row lg:justify-between items-center space-y-4 lg:space-y-0">
          {/* Logo Section */}
          <Link to="/" className="flex items-center space-x-2" onClick={() => window.scrollTo(0, 0)}>
            <img src="/starfetcher-192.png" alt="StarFetcher Logo" className="w-10 h-10 lg:w-12 lg:h-12" />
            <p className="text-white text-[26px] lg:text-[36px] font-bold">StarFetcher</p>
          </Link>

          {/* Footer Links */}
          <div className="flex space-x-6 text-gray-400">
            <Link to="/about" className="hover:text-white transition-colors duration-300">About Us</Link>
            <Link to="/contact" className="hover:text-white transition-colors duration-300">Contact Us</Link>
            <Link to="/privacy" className="hover:text-white transition-colors duration-300">Privacy Policy</Link>
          </div>
        </div>

        {/* Additional Information Section */}
        <div className="mt-6 text-center lg:text-left">
          <p className="text-gray-400 text-sm">
            StarFetcher is your dedicated guide for tracking valuable stars in real time. Powered by advanced AI filtering, our platform enables optimized star hunts to enhance your experience and ensure no opportunity goes unnoticed.
          </p>
        </div>

        {/* Social Media Links */}
        <div className="flex justify-center lg:justify-start space-x-6 mt-4">
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-white">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="hover:text-white">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="hover:text-white">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="hover:text-white">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>

        {/* Copyright */}
        <div className="mt-6 text-center text-gray-500 text-sm">
          &copy; {new Date().getFullYear()} StarFetcher. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
