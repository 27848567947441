// Navbar.js
import React, { useEffect, useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { navLinks } from "../data";
import StarCountdown from "./StarCountdown";
import { useStarContext } from "../../components/context/StarContext"; // Update to useStarContext
import { useAuthContext } from "../../components/context/AuthContext"; // Import useAuthContext
import { FaRegUser } from "react-icons/fa";

const Navbar = ({ toggleNightMode, toggleRefreshStars }) => {
  const [active, setActive] = useState("ai-efficient-stars");
  const { preferredStars, stars, refreshStars } = useStarContext(); // Update to useStarContext
  const { user, toggleAuthWindow } = useAuthContext(); // Access user and toggleAuthWindow from context

  // Handle setting active section on scroll
  useEffect(() => {
    const sections = document.querySelectorAll("div[id]");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActive(entry.target.id);
          }
        });
      },
      {
        threshold: 0.2,
        rootMargin: '0px 0px -50% 0px',
      }
    );

    sections.forEach((section) => observer.observe(section));

    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  const handleLoginClick = () => {
    console.log("Login button clicked - toggling auth popup");
    toggleAuthWindow(); // Trigger the auth popup
  };

  return (
    <nav className="w-full flex space-x-2 items-center justify-between px-[2vw] py-[2vw] z-40 bg-gray-900 bg-opacity-80">
<div className="flex flex-col lg:flex-row lg:items-center lg:space-x-8 w-2/3">
  {/* Logo Section */}
  <Link
    smooth
    to="/"
    className="flex items-center"
    onClick={() => {
      setActive("hero");
      window.scrollTo(0, 0);
    }}
  >
    <img
      src="/starfetcher-192.png"
      alt="StarFetcher Logo"
      className="w-10 h-10 lg:w-12 lg:h-12 mr-2"
    />
    <p className="text-white text-[26px] lg:text-[36px] font-bold pointer-events-auto cursor-pointer flex">
      StarFetcher
    </p>
  </Link>

  {/* Navigation Links */}
  <ul className="list-none flex flex-row justify-start gap-[2vw] pt-2 pl-2 lg:items-center">
    {navLinks.map((nav) => (
      <li
        key={nav.id}
        className={`relative flex items-center ${
          active === nav.id ? "text-white" : "text-slate-500"
        } hover:text-white text-[3vw] lg:text-[1.5vw] font-bold pointer-events-auto cursor-pointer`}
      >
        {active === nav.id && (
          <div className="absolute bottom-[-8px] w-full h-1 bg-quaternary"></div>
        )}
        <Link smooth to={`${nav.path}`} onClick={() => setActive(nav.id)}>
          {nav.title}
        </Link>
      </li>
    ))}
  </ul>
</div>

<div className="flex flex-row items-start lg:items-center mb-auto mt-3 space-x-0 space-x-[1.5vw]">
  {/* Login or Profile Button */}
  <div className="w-full flex lg:justify-start justify-end">
    <button
      onClick={handleLoginClick}
      className={`text-[3vw] lg:text-[2vw] w-[15vw] lg:w-[10vw] h-[5vw] lg:h-[4vw] relative font-semibold transition duration-1000 ease-in-out flex justify-center items-center px-[1vw] transition-transform hover:scale-110 rounded-full text-white ${
        user ? "bg-green-500 hover:bg-green-800" : "bg-blue-400 hover:bg-teal-800"
      }`}
    >
      Profile
    </button>
  </div>

  {/* Countdown Component */}
  <div className="flex transition-transform hover:scale-110 duration-1000">
    <StarCountdown
      toggleRefreshStars={toggleRefreshStars}
      className="w-auto rounded-full h-10 text-white font-semibold bg-teal-400 transition-colors duration-1000 ease-in-out hover:bg-opacity-80 text-center"
    />
  </div>
</div>

    </nav>
  );
};

export default Navbar;
