import React from "react";
import { HiOutlineSquares2X2 } from "react-icons/hi2"; // Import Stack View icon
import { TbColumns } from "react-icons/tb"; // Import List View icon

const ViewModeToggle = ({ viewMode, setViewMode }) => {
  return (
    <div className="flex space-x-[1vw]">
      {/* Stack View Button */}
      <button
        className={`flex items-center justify-center p-1 rounded-full hover:bg-gray-600 transition ${
          viewMode === "stack" ? "bg-gray-700 text-white" : "text-gray-400"
        }`}
        onClick={() => setViewMode("stack")}
        aria-label="Stack View"
      >
        <HiOutlineSquares2X2 className="text-[5vw] lg:text-[3vw]" />
      </button>

      {/* List View Button */}
      <button
        className={`flex items-center justify-center p-1 rounded-full hover:bg-gray-600 transition ${
          viewMode === "list" ? "bg-gray-700 text-white" : "text-gray-400"
        }`}
        onClick={() => setViewMode("list")}
        aria-label="List View"
      >
        <TbColumns className="text-[5vw] lg:text-[3vw]" />
      </button>
    </div>
  );
};

export default ViewModeToggle;
