import React from 'react';
import { GiMining } from 'react-icons/gi';
import { shadowColorMap } from "./starMapping";

const buttonClasses = "rounded-full px-4 py-2 font-semibold text-white transition-transform duration-300 transform hover:scale-110";

const WelcomeBannerFilters = () => {
  const getBackgroundColor = (color) => ({ backgroundColor: color });

  return (
    <div className="bg-gray-700 p-3 rounded-lg shadow-md flex flex-col space-y-3">
      <p className="text-white text-[3vw] lg:text-[2vw] font-bold">Filter how you need</p>
      
      {/* Region Filter */}
      <div className="flex items-center">
        <div className=" flex items-center justify-center">
          <button className={`${buttonClasses} text-[2vw] lg:text-[1vw] bg-purple-900 hover:bg-purple-700 shadow-md`}>
            Region
          </button>
        </div>
        <div className="ml-3">
          <p className="text-gray-300 text-[3vw] lg:text-[1.5vw] font-bold">Filter by specific regions.</p>
          <p className="text-gray-400 text-[2vw] lg:text-[1.5vw]">See only stars within chosen regions for targeted exploration.</p>
        </div>
      </div>

      {/* Free to Play Filter */}
      <div className="flex items-center">
        <div className=" flex items-center justify-center">
          <button className={`${buttonClasses} text-[2vw] lg:text-[1vw] whitespace-nowrap bg-green-900 hover:bg-green-700 shadow-md`}>
            Free to Play
          </button>
        </div>
        <div className="ml-3">
          <p className="text-gray-300 text-[3vw] lg:text-[1.5vw] font-bold">Show only free-to-play stars.</p>
          <p className="text-gray-400 text-[2vw] lg:text-[1vw]">Filter stars accessible without any paid content.</p>
        </div>
      </div>

      {/* Tiers Filter */}
      <div className="flex items-center">
        <div className=" flex flex-row space-x-2 items-center min-w-[75px] lg:min-w-[100px]">
          {['7', '8', '9'].map((tier) => (
            <button
              key={tier}
              className="text-[3vw] lg:text-[1vw] w-[8vw] lg:w-[3vw] h-[8vw] lg:h-[3vw] flex items-center justify-center text-white rounded-full transition-transform duration-300 transform hover:scale-125"
              style={getBackgroundColor(shadowColorMap[tier])}
            >
              T{tier}
            </button>
          ))}
        </div>
        <div className="ml-3">
          <p className="text-gray-300 text-[3vw] lg:text-[1.5vw] font-bold">Filter by star tier levels.</p>
          <p className="text-gray-400 text-[2vw] lg:text-[1vw]">Select specific tiers for customized searches and results.</p>
        </div>
      </div>

      {/* Time Filter */}
      <div className="flex items-center">
        <div className=" flex items-center justify-center">
          <button className={`${buttonClasses} text-[2vw] px-[4vw] lg:px-[2vw] py-[2vw] lg:py-[.75vw] lg:text-[1vw] bg-green-600 hover:bg-green-500 shadow-md`}>
            10m
          </button>
        </div>
        <div className="ml-3">
          <p className="text-gray-300 text-[3vw] lg:text-[1.5vw] font-bold">Set a time filter for recent stars.</p>
          <p className="text-gray-400 text-[2vw] lg:text-[1vw]">See stars spotted within the last 10 minutes for up-to-date tracking.</p>
        </div>
      </div>

      {/* Mining Icon Filter */}
      <div className="flex items-center">
        <div className=" flex items-center justify-center px-2">
          <GiMining
            className="cursor-pointer text-[8vw] lg:text-[4vw] text-yellow-500 hover:scale-125 transition-transform duration-300 hover:drop-shadow-[0_0_10px_rgba(255,215,0,0.8)]"
            title="Filter by active star location"
          />
        </div>
        <div className="ml-3">
          <p className="text-gray-300 text-[3vw] lg:text-[1.5vw] font-bold">Mark your active star.</p>
          <p className="text-gray-400 text-[2vw] lg:text-[1vw]">Highlight the stars at your current location to track the stars at your current location.</p>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBannerFilters;
