import React, { useEffect } from 'react';
import WelcomeBanner from '../../components/common/WelcomeBanner';
import StarFeed from '../../components/common/StarFeed';
import StarFetcherAssistant from '../../components/common/StarFetcherAssistant';
import SubscribeAndRefer from '../../components/common/SubscribeAndRefer';
import { useStarContext } from '../../components/context/StarContext';
import { useAuthContext } from '../../components/context/AuthContext'; // Import auth context
import StarPreferences from '../../components/common/StarPreferences';

const Dashboard = ({ refreshToggle, toggleWelcome, isWelcomeVisible }) => {
  const { stars, preferredStars, dislikedStars, preferredStarsList, dislikedStarsList, togglePreferredStar, toggleDislikedStar, refreshStars } = useStarContext();
  const { user } = useAuthContext(); // Get user from auth context
  
  const isUserLoggedIn = !!user; // Determine if user is logged in based on the presence of a user object

  useEffect(() => {
    refreshStars();
  }, [refreshToggle]);

  return (
    <div className="w-full p-[2vw] lg:p-[1vw] bg-gray-900 rounded-lg">
      {/* Pass isUserLoggedIn to control initial state of WelcomeBanner */}
      <WelcomeBanner toggleWelcome={toggleWelcome} isWelcomeVisible={isWelcomeVisible} />
      <div>
        <StarFeed
          stars={stars}
          preferredStars={preferredStars}
          dislikedStars={dislikedStars}
          togglePreferredStar={togglePreferredStar}
          toggleDislikedStar={toggleDislikedStar}
        />
      </div>

      <div className="py-10">
        <StarFetcherAssistant />
        {/* <SubscribeAndRefer /> */}
      </div>
    </div>
  );
};

export default Dashboard;
