import React, { useEffect, useState } from "react";

// Helper function to calculate the remaining time within the current 6-minute window
const calculateRemainingTime = (timeLanded, interval = 360) => {
  const now = new Date(); // Current time
  const landedTime = new Date(`${timeLanded}Z`); // Convert time_landed to Date object in UTC
  const elapsedSeconds = Math.floor((now - landedTime) / 1000); // Total time elapsed in seconds

  // Get the remaining time in the current 6-minute cycle
  const remainingTimeInCycle = interval - (elapsedSeconds % interval);
  return remainingTimeInCycle >= 0 ? remainingTimeInCycle : 0; // Ensure no negative values
};

// VerticalTimerBar Component
const VerticalTimerBar = ({
  timeLanded,
  height = 5, // Height in vw (default 10vw)
  width = 0.5,   // Width in vw (default 1vw)
  duration = 360,
  currentTier,
  onTierUpdate,
}) => {
  const [remainingTime, setRemainingTime] = useState(() => calculateRemainingTime(timeLanded, duration)); // Initial remaining time
  const [tierUpdated, setTierUpdated] = useState(false); // Lock to ensure single tier update per cycle

  // Update remaining time every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1; // Decrease remaining time
        } else {
          return duration; // Reset to full duration
        }
      });
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [duration]);

  // Effect to handle tier update when the timer reaches zero
  useEffect(() => {
    if (remainingTime === 0 && !tierUpdated && currentTier > 1) {
      const newTierLevel = currentTier - 1; // Decrement the tier
      onTierUpdate(newTierLevel); // Notify parent component
      setTierUpdated(true); // Set lock to prevent multiple updates
    } else if (remainingTime > 0) {
      setTierUpdated(false); // Reset lock for the next cycle
    }
  }, [remainingTime, currentTier, onTierUpdate, tierUpdated]);

  // Calculate the height percentage for the bar to shrink from top to bottom
  const progressHeight = `${(remainingTime / duration) * 100}%`;

  return (
    <div
      className="relative overflow-hidden rounded-full bg-red-500"
      style={{
        height: `${height}vw`, // Total height of the timer (responsive)
        width: `${width}vw`,   // Total width of the timer (responsive)
      }}
    >
      {/* Shrinking White Bar from Top to Bottom */}
      <div
        className="absolute bottom-0 left-0 bg-white rounded-full transition-all ease-linear"
        style={{
          height: progressHeight, // White bar height shrinks downwards over time
          width: "100%",          // Full width
          transformOrigin: "bottom", // Start shrinking from the top to reveal the red background
          transition: "height 1s linear", // Ensure smooth height transition
        }}
      ></div>
    </div>
  );
};

export default VerticalTimerBar;
