import React from "react";
import { FaHeart } from "react-icons/fa6"; // Import the Heart icon

const NoPreferredStars = ({ message }) => {
  return (
    <div className="flex flex-col pt-[2.0vw] lg:p-[1vw] mt-[2vw] items-center hover-wrapper justify-center text-center rounded-lg bg-gray-800 text-white">
      <FaHeart className="text-[10vw] text-red-500 mb-4 animate-pulse transition-all duration-300 ease-in-out hover:text-yellow-400 hover:filter hover:drop-shadow-[0_0_20px_rgba(255,255,0,0.8)]" />
      <p className="text-[2vw] mb-[1vw]">{message}</p>
      <style jsx>{`
        .hover-wrapper {
          transform-origin: center;
          transition: transform 0.3s ease-in-out;
        }

        .hover-wrapper:hover {
          transform: scale(1.05);
        }
      `}</style>
    </div>
  );
};

export default NoPreferredStars;
