import React from "react";
import { shadowColorMap } from "./starMapping";

const StarFiltersTierButtons = ({ activeFilters, setActiveFilters }) => {
  const toggleTier = (index) => {
    setActiveFilters((prev) => {
      const updatedTiers = [...prev.tiers];
      updatedTiers[index] = !updatedTiers[index];
      return { ...prev, tiers: updatedTiers };
    });
  };

  const getTierStyle = (index) => ({
    backgroundColor: activeFilters.tiers[index] ? shadowColorMap[index + 1] : "gray",
    boxShadow: activeFilters.tiers[index] ? `0px 0px 10px ${shadowColorMap[index + 1]}` : "none",
  });

  return (
<div className="flex flex-col space-y-4 lg:space-y-0">
  {/* Tiers Label and Buttons */}
  <div className="flex flex-row lg:flex-col gap-4 items-start lg:items-center">
    {/* Tiers Label */}
    <span className="text-[3vw] text-white font-semibold shrink-0">Tiers:</span>

    {/* Tier Buttons */}
    <div
      className="grid grid-cols-3 lg:grid-cols-9 gap-2 lg:gap-[0.5vw]"
    >
      {Array.from({ length: 9 }).map((_, index) => (
        <button
          key={index}
          onClick={() => toggleTier(index)}
          className="w-[8vw] h-[8vw] lg:h-[3vw] lg:w-[3vw] text-[2vw] lg:text-[1vw] flex font-bold text-white items-center justify-center rounded-full"
          style={getTierStyle(index)}
        >
          T{index + 1}
        </button>
      ))}
    </div>
  </div>

    </div>
  );
};

export default StarFiltersTierButtons;
