import React from "react";
import { FaHandPointRight } from "react-icons/fa"; // Importing icon

const MoreFiltersButton = ({ onClick, showFilters }) => {
  return (
    <button
      onClick={onClick}
      className={`flex items-center space-x-2 px-[2vw] py-[1vw] font-semibold rounded-full transition-colors
      ${showFilters ? 'bg-green-600 hover:bg-green-500' : 'bg-blue-600 hover:bg-blue-500'} text-white`}
    >
      {/* Rotate the icon when showFilters is true */}
      <FaHandPointRight className={`transform text-[2vw] text-[3vw] lg:text-[1.5vw] transition-transform ${showFilters ? 'rotate-90' : 'rotate-0'}`} />
      <span className="text-[3vw] lg:text-[1.5vw]">More Filters</span>
    </button>
  );
};

export default MoreFiltersButton;
