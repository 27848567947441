import React from 'react';
import { Link } from 'react-router-dom';

const WelcomeBannerPromoMessages = () => {
  return (
    <>
      <p className="text-gray-400 mt-6 text-[3vw] lg:text-[1vw]">✨ Track your preferred stars in real-time with the <strong>Star Feed</strong>.</p>
      <p className="text-gray-400 mt-2 text-[3vw] lg:text-[1vw]">🎉 Join our <strong>Subscribe & Refer</strong> program for exclusive benefits!</p>
      <p className="text-gray-400 mt-2 text-[3vw] lg:text-[1vw]">📝 Need help? Visit our <Link to="/guides" className="text-teal-400 hover:text-teal-500 underline">Help & Guides</Link> section for tips.</p>
    </>
  );
};

export default WelcomeBannerPromoMessages;
