import React, { useEffect } from "react";
import StarPanelContainer from "./StarPanelContainer";

const StarFeed = ({ stars, preferredStars, dislikedStars, togglePreferredStar, toggleDislikedStar }) => {
  useEffect(() => {
    console.log("StarFeed - Updated Stars:", stars);
  }, [stars]);

  return (
    <div className="w-full space-y-8">
      {/* Preferred Stars Section */}
      <StarPanelContainer
        title="Preferred Stars"
        stars={stars}
        preferredStars={preferredStars}
        dislikedStars={dislikedStars}          // Add this line
        togglePreferredStar={togglePreferredStar}
        toggleDislikedStar={toggleDislikedStar} // Add this line
      />

      {/* All Stars Section */}
      <StarPanelContainer
        title="All Stars"
        stars={stars}
        preferredStars={preferredStars}
        dislikedStars={dislikedStars}          // Add this line
        togglePreferredStar={togglePreferredStar}
        toggleDislikedStar={toggleDislikedStar} // Add this line
      />
    </div>
  );
};

export default StarFeed;
