import React, { useState } from "react";
import { getAssistantPromoImage } from "../../utils/assetsData";

const StarFetcherAssistant = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      id="StarFetcherAssistant"
      className="relative w-full bg-opacity-80 bg-gray-800 text-white font-bold py-8 px-6 rounded-lg shadow-lg mb-8 overflow-hidden"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Header */}
      <h2 className="text-[4vw] font-bold text-center my-8">StarFetcher Assistant</h2>

      {/* Promo Image */}
      <div className="relative h-full w-full flex items-center justify-center">
        <img
          src={getAssistantPromoImage()}
          alt="StarFetcher Assistant Promo"
          className="rounded-lg object-cover h-full w-full transition-opacity duration-2000"
        />

        {/* Coming Soon Overlay */}
        <div
          className={`absolute inset-0 flex items-center justify-center rounded-lg bg-orange-500 bg-opacity-30 transition-opacity duration-2000 ${
            isHovered ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="text-3xl font-extrabold text-white">Coming Soon!</div>
        </div>
      </div>
    </div>
  );
};

export default StarFetcherAssistant;
