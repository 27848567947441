import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove, setDoc } from "firebase/firestore";
import { useAuthContext } from "./AuthContext";
import { db } from "../../firebaseConfig";

const BASE_URL = "http://localhost:8080/api/stars";

// Create the StarContext
const StarContext = createContext();

// Create a provider component
export const StarProvider = ({ children }) => {
    const { user } = useAuthContext();
    const [stars, setStars] = useState([]);
    const [preferredStars, setPreferredStars] = useState([]);
    const [dislikedStars, setDislikedStars] = useState([]);
    const [preferredStarsList, setPreferredStarsList] = useState([]);
    const [dislikedStarsList, setDislikedStarsList] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const fetchPreferenceLists = async () => {
      if (!user) {
          console.warn("No user is logged in. Cannot fetch preferences.");
          setPreferredStarsList([]);
          setDislikedStarsList([]);
          return;
      }
  
      try {
          const userDocRef = doc(db, "users", user.userID);
          const docSnapshot = await getDoc(userDocRef);
  
          if (docSnapshot.exists()) {
              const data = docSnapshot.data();
              const preferredList = data.preferred || [];
              const dislikedList = data.dislikedStars || [];
  
              setPreferredStarsList(preferredList);
              setDislikedStarsList(dislikedList);
  
              console.log("Fetched and updated Firestore preferences:", { preferredList, dislikedList });
          } else {
              console.log("No document found for the user. Resetting lists to empty.");
              setPreferredStarsList([]);
              setDislikedStarsList([]);
          }
      } catch (error) {
          console.error("Error fetching preferences from Firestore:", error);
          setPreferredStarsList([]);
          setDislikedStarsList([]);
      }
  };
  

    const fetchPreferredStars = async () => {
        if (!user) return; // Only fetch if a user is logged in

        try {
            const userDocRef = doc(db, "users", user.userID);
            const docSnapshot = await getDoc(userDocRef);

            if (docSnapshot.exists()) {
                const userPreferred = docSnapshot.data().preferred || [];
                setPreferredStars(userPreferred);
                console.log("Fetched preferred stars:", userPreferred);
            } else {
                console.log("No preferred stars found; initializing empty list.");
                setPreferredStars([]); // Initialize as empty if no preferred stars found
            }
        } catch (error) {
            console.error("Error fetching preferred stars from Firestore:", error);
        }
    };

    const updatePreferredStarsInFirestore = async (updatedPreferredStars) => {
        if (!user) return;
        try {
            const userDocRef = doc(db, "users", user.userID);
            await setDoc(
                userDocRef,
                { preferred: updatedPreferredStars },
                { merge: true }
            );
            console.log("Updated preferred stars in Firestore:", updatedPreferredStars);
        } catch (error) {
            console.error("Error updating preferredStars in Firestore:", error);
        }
    };

    const refreshStars = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/all?timestamp=${Date.now()}`);
            if (Array.isArray(response.data)) {
                setStars(response.data);
            }
        } catch (error) {
            console.error("Error fetching stars:", error);
        }
    };

    const triggerRefresh = () => setRefresh((prev) => !prev);

    const updateFirestoreList = async (userID, listName, location, add) => {
        const userDocRef = doc(db, "users", userID);
        try {
            if (add) {
                await updateDoc(userDocRef, {
                    [listName]: arrayUnion(location),
                });
            } else {
                await updateDoc(userDocRef, {
                    [listName]: arrayRemove(location),
                });
            }
            console.log(`Successfully updated ${listName} in Firestore.`);
        } catch (error) {
            console.error(`Error updating ${listName} in Firestore:`, error);
        }
    };

    const togglePreferredStar = (star) => {
      const { location } = star;
  
      setPreferredStars((prevPreferredStars) => {
          const isPreferred = prevPreferredStars.includes(location);
          const updatedPreferredStars = isPreferred
              ? prevPreferredStars.filter((loc) => loc !== location)
              : [...prevPreferredStars, location];
  
          // Remove from dislikedStars if adding to preferredStars
          if (!isPreferred && dislikedStars.includes(location)) {
              setDislikedStars((prevDislikedStars) =>
                  prevDislikedStars.filter((loc) => loc !== location)
              );
              updateFirestoreList(user.userID, "dislikedStars", location, false); // Remove from Firestore
          }
  
          updatePreferredStarsInFirestore(updatedPreferredStars);
          fetchPreferenceLists();
          console.log("Preferred Stars:", updatedPreferredStars);
          return updatedPreferredStars;
      });
  };
  
  const toggleDislikedStar = (star) => {
      const { location } = star;
  
      setDislikedStars((prevDislikedStars) => {
          const isDisliked = prevDislikedStars.includes(location);
          const updatedDislikedStars = isDisliked
              ? prevDislikedStars.filter((loc) => loc !== location)
              : [...prevDislikedStars, location];
  
          // Remove from preferredStars if adding to dislikedStars
          if (!isDisliked && preferredStars.includes(location)) {
              setPreferredStars((prevPreferredStars) =>
                  prevPreferredStars.filter((loc) => loc !== location)
              );
              updatePreferredStarsInFirestore(
                  preferredStars.filter((loc) => loc !== location) // Update Firestore
              );
          }
  
          updateFirestoreList(user.userID, "dislikedStars", location, !isDisliked);
          fetchPreferenceLists();
          console.log("Disliked Stars:", updatedDislikedStars);
          return updatedDislikedStars;
      });
  };
  

    useEffect(() => {
        refreshStars();
    }, [refresh]);

    // Fetch preferred stars whenever user changes
    useEffect(() => {
        if (user) {
            fetchPreferredStars();
            const fetchLists = async () => {
              fetchPreferenceLists();
              console.log("Preferred Stars List:", preferredStarsList);
              console.log("Disliked Stars List:", dislikedStarsList);
            };
            fetchLists();
        } else {
            // Reset lists to empty arrays if the user is logged out
            setPreferredStars([]);
            setDislikedStars([]);
        }
        
  
    }, [user]);

    return (
        <StarContext.Provider
            value={{
                user,
                stars,
                preferredStars,
                dislikedStars,
                preferredStarsList,
                dislikedStarsList,
                refreshStars: triggerRefresh,
                togglePreferredStar,
                toggleDislikedStar,
            }}
        >
            {children}
        </StarContext.Provider>
    );
};

// Custom hook to use StarContext
export const useStarContext = () => {
    return useContext(StarContext);
};
