import React from "react";
import StarPanelDetailed from "./StarPanelDetailed";
import StarPanelStack from "./StarPanelStack";
import { colorMap, shadowColorMap } from "./starMapping";

const StarPanelList = ({
  star,
  viewMode,
  setActiveFilters = () => {},   // Default to no-op if undefined
  activeFilters = [],              // Default to empty array
  togglePreferredStar = () => {},   // Default to no-op
  toggleDislikedStar = () => {},    // Default to no-op
  preferredStars = [],              // Default to empty array
  dislikedStars = []                // Default to empty array
}) => {
  // Extract tier number from the size (e.g., "T3" -> "3")
  const tierNumber = star.size.replace("T", "");
  // Determine colors based on the tier
  const sizeColorClass = colorMap[tierNumber] || "text-gray-500 border-gray-500";
  const regionColorClass = "text-gray-400 border-gray-400";

  // Render based on the view mode
  if (viewMode === "list") {
    return (
      <StarPanelDetailed
        star={star}
        sizeColorClass={sizeColorClass}
        regionColorClass={regionColorClass}
        setActiveFilters={setActiveFilters}
        activeFilters={activeFilters}
        togglePreferredStar={togglePreferredStar}
        toggleDislikedStar={toggleDislikedStar}
        preferredStars={preferredStars}
        dislikedStars={dislikedStars}
      />
    );
  }

  return (
    <StarPanelStack
      star={star}
      setActiveFilters={setActiveFilters}
      activeFilters={activeFilters}
      preferredStars={preferredStars}
      togglePreferredStar={togglePreferredStar}
      dislikedStars={dislikedStars}
      toggleDislikedStar={toggleDislikedStar}
      sizeColorClass={sizeColorClass}
      regionColorClass={regionColorClass}
    />
  );
};

export default StarPanelList;
