// AuthContext.jsx
import React, { createContext, useContext, useEffect, useState } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";

const AuthContext = createContext();

export const AuthProvider = ({ children, user, setUser }) => {
  const [isAuthWindowOpen, setAuthWindowOpen] = useState(false);

  const toggleAuthWindow = () => setAuthWindowOpen((prev) => !prev);

  const loginWithToken = async (token, tokenType = "firebase") => {
    console.log("Starting login process with token:", token, "Token type:", tokenType);

    try {
        const response = await fetch(`${import.meta.env.VITE_API_URL}/api/verify-token`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ token, tokenType }), // Send both token and tokenType
        });

        if (!response.ok) throw new Error("Token verification failed");

        const data = await response.json();
        const userData = { userID: data.userID, email: data.email, name: data.name };
        console.log("Unpacking userData: :", userData);
        setUser(userData); // Update user in App.jsx
        localStorage.setItem("user", JSON.stringify(userData));

        await fetchUserFavorites(data.userID);
    } catch (error) {
        console.error("Login failed:", error);
        setUser(null);
    }
};

  const fetchUserFavorites = async (userID) => {
    try {
      const userDocRef = doc(db, "users", userID);
      const docSnapshot = await getDoc(userDocRef);

      if (docSnapshot.exists()) {
        const userPreferred = docSnapshot.data().preferred || [];
        // Here, set preferred stars if necessary
      } else {
        await setDoc(userDocRef, { preferred: [] });
      }
    } catch (error) {
      console.error("Error fetching user preferred stars:", error);
    }
  };

  const logout = () => {
    setUser(null); // Clear user in App.jsx
    console.log("Logging out user: ", {user})
    localStorage.removeItem("user");
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, [setUser]);

  return (
    <AuthContext.Provider value={{ user, loginWithToken, logout, isAuthWindowOpen, toggleAuthWindow }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
