import React, { useState, useEffect } from "react";
import StarFiltersButton from "./StarFiltersButton";
import StarFiltersTierButtons from "./StarFiltersTierButtons";
import StarFiltersLocationButton from "./StarFiltersLocationButton";
import StarFiltersMaxTimeButton from "./StarFiltersMaxTimeButton";
import StarFiltersRegionButtons from "./StarFiltersRegionButtons";
import MoreFiltersButton from "./MoreFiltersButton";

const StarFilters = ({ inline, showAdditionalFilters = false, activeFilters, setActiveFilters, setShowFilters, showFilters }) => {
  const [expanded, setExpanded] = useState(false);
  const [showRegionFilters, setShowRegionFilters] = useState(false);

  useEffect(() => {
    if (showAdditionalFilters) {
      setTimeout(() => setExpanded(true), 50);
    } else {
      setExpanded(false);
    }
  }, [showAdditionalFilters]);

  const toggleRegionFilters = () => {
    setShowRegionFilters((prev) => !prev);
  };

  return (
    <div className={`flex items-center flex-wrap ${inline ? "space-x-2" : "bg-gray-800 bg-opacity-30 p-4 rounded-lg"}`}>
      {inline ? (
        <>
          <StarFiltersButton filterType="efficient" activeFilters={activeFilters} setActiveFilters={setActiveFilters} label="Most Efficient" />
          <StarFiltersButton filterType="mostRecent" activeFilters={activeFilters} setActiveFilters={setActiveFilters} label="Most Recent" />
          <MoreFiltersButton onClick={() => setShowFilters(!showFilters)} showFilters={showFilters} />
        </>
      ) : (
        <div className="transition-all duration-700 ease-in-out origin-top">
          <div className="flex items-center space-x-3">
            <div className="flex items-center space-x-6 my-2 mx-5">
              <StarFiltersTierButtons activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
            </div>
            <div className="flex space-y-6 lg:space-y-0 space-x-2 my-2">
              <div className="flex">
                <span className="text-[3vw] items-start text-white font-semibold shrink-0">Filters:</span>
              </div>
              <div className="flex flex-col lg:flex-row lg:space-x-[1vw] lg:space-y-[0] space-y-[2vw]">
                <StarFiltersButton filterType="freeToPlay" activeFilters={activeFilters} setActiveFilters={setActiveFilters} label="Free-To-Play" />
                <StarFiltersButton filterType="region" activeFilters={activeFilters} setActiveFilters={setActiveFilters} label="Region" onClick={toggleRegionFilters} />
                <StarFiltersMaxTimeButton activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
              </div>
            </div>
          </div>
          <StarFiltersRegionButtons activeFilters={activeFilters} setActiveFilters={setActiveFilters} show={showRegionFilters} />
        </div>
      )}
    </div>
  );
};

export default StarFilters;
