// mappings.js

// Color mapping for tiers 1 to 9
export const colorMap = {
    '1': "text-blue-400 border-blue-400",    // Light Blue
    '2': "text-teal-500 border-teal-500",     // Teal
    '3': "text-green-500 border-green-500",   // Light Green
    '4': "text-yellow-400 border-yellow-400", // Yellow
    '5': "text-orange-400 border-orange-400", // Orange
    '6': "text-red-500 border-red-500",       // Red
    '7': "text-pink-500 border-pink-500",     // Pink
    '8': "text-purple-600 border-purple-600", // Light Purple
    '9': "text-purple-800 border-purple-800"  // Dark Purple
  };

  export const shadowColorMap = {
    '1': "rgba(30,144,255,0.6)",   // Blue glow
    '2': "rgba(0,128,128,0.6)",    // Teal glow
    '3': "rgba(50,205,50,0.6)",    // Green glow
    '4': "rgba(255,255,0,0.6)",    // Yellow glow
    '5': "rgba(255,165,0,0.6)",    // Orange glow
    '6': "rgba(255,0,0,0.6)",      // Red glow
    '7': "rgba(255,105,180,0.6)",  // Pink glow
    '8': "rgba(147,112,219,0.6)",  // Light Purple glow
    '9': "rgba(75,0,130,0.6)"      // Dark Purple glow
  };
  
  // Comprehensive region color mapping
  export const regionColorMap = {
    "Asgarnia": "text-green-400 border-green-400",
    "Misthalin": "text-blue-500 border-blue-500",
    "Kandarin": "text-yellow-500 border-yellow-500",
    "Morytania": "text-purple-600 border-purple-600",
    "Feldip Hills": "text-red-500 border-red-500",
    "Karamja": "text-orange-400 border-orange-400",
    "Fremennik": "text-teal-500 border-teal-500",
    "Wilderness": "text-gray-500 border-gray-500",
    "Kourend": "text-indigo-500 border-indigo-500",
    "Tirannwn": "text-pink-500 border-pink-500",
    "Desert": "text-yellow-600 border-yellow-600",
    "Western Provinces": "text-cyan-500 border-cyan-500",
    "Pisc/Gnome": "text-green-500 border-green-500",
    "Kebos": "text-purple-500 border-purple-500",
    "Wildy": "text-red-600 border-red-600",
    "Other": "text-gray-500 border-gray-500"  // Default for any missing region
  };

  export const regionMap = {
      "Asgarnia": [
        "Crafting Guild",
        "Rimmington Mine",
        "Port Sarim",
        "Draynor Village",
        "Falador Park",
        "South of Falador",
        "South of Taverley",
        "Taverley",
        "Ice Mountain",
        "South of Heroes' Guild",
        "South of Burthorpe",
        "North of Burthorpe",
        "Death Plateau"
      ],
      "Crandor and Karamja": [
        "Musa Point",
        "Karamja Volcano",
        "Brimhaven",
        "Shilo Village",
        "Tai Bwo Wannai",
        "Kharazi Jungle",
        "Crandor Island"
      ],
      "Feldip Hills and the Isle of Souls": [
        "Feldip Hills",
        "South of Castle Wars",
        "South of Yanille",
        "Gu'Tanoth",
        "Ogre City",
        "South of Feldip Hills",
        "Isle of Souls"
      ],
      "Fossil Island and Mos Le'Harmless": [
        "Fossil Island",
        "Mos Le'Harmless"
      ],
      "Fremennik Lands and Lunar Isle": [
        "Rellekka",
        "Miscellania",
        "Etceteria",
        "Neitiznot",
        "Jatizso",
        "Lunar Isle"
      ],
      "Great Kourend": [
        "Hosidius",
        "Shayzien",
        "Arceuus",
        "Lovakengj",
        "Piscarilius"
      ],
      "Kandarin": [
        "Seers' Village",
        "Catherby",
        "Ardougne",
        "Tree Gnome Stronghold",
        "Tree Gnome Village",
        "Castle Wars",
        "Fishing Guild",
        "Hemenster",
        "East Ardougne",
        "West Ardougne",
        "South of Ardougne",
        "South of Seers' Village",
        "South of Sinclair Mansion",
        "North of Seers' Village",
        "North of Ardougne",
        "North of Castle Wars"
      ],
      "Kebos Lowlands": [
        "Mount Quidamortem",
        "Lizardman Canyon",
        "Lizardman Settlement",
        "Kebos Swamp",
        "Farming Guild",
        "South of Farming Guild"
      ],
      "Kharidian Desert": [
        "Al Kharid",
        "Duel Arena",
        "Pollnivneach",
        "Nardah",
        "Sophanem",
        "Pyramid",
        "Bedabin Camp",
        "Uzer",
        "Desert Mining Camp",
        "South of Desert Mining Camp",
        "South of Uzer",
        "South of Bedabin Camp",
        "South of Pollnivneach",
        "South of Nardah",
        "South of Sophanem"
      ],
      "Misthalin": [
        "Lumbridge",
        "Draynor Village",
        "Varrock",
        "Barbarian Village",
        "Edgeville",
        "South of Varrock",
        "South of Lumbridge",
        "South of Draynor Village",
        "North of Varrock",
        "North of Edgeville"
      ],
      "Morytania": [
        "Canifis",
        "Port Phasmatys",
        "Mort'ton",
        "Burgh de Rott",
        "Meiyerditch",
        "Slepe",
        "South of Port Phasmatys",
        "South of Canifis",
        "South of Mort'ton",
        "South of Burgh de Rott",
        "South of Meiyerditch",
        "South of Slepe"
      ],
      "Piscatoris and the Gnome Stronghold": [
        "Piscatoris Fishing Colony",
        "Eagles' Peak",
        "Tree Gnome Stronghold",
        "Tree Gnome Village",
        "South of Piscatoris",
        "South of Eagles' Peak",
        "South of Tree Gnome Stronghold",
        "South of Tree Gnome Village"
      ],
      "Tirannwn": [
        "Lletya",
        "Prifddinas",
        "Isafdar",
        "South of Lletya",
        "South of Prifddinas",
        "South of Isafdar"
      ],
      "Varlamore": [
        "Camdozaal",
        "Camdozaal Ruins",
        "South of Camdozaal",
        "South of Camdozaal Ruins"
      ],
      "Wilderness": [
        "Edgeville Wilderness",
        "Bandit Camp",
        "Dark Warriors' Fortress",
        "Lava Maze",
        "Wilderness Volcano",
        "Wilderness Agility Course",
        "South of Edgeville Wilderness",
        "South of Bandit Camp",
        "South of Dark Warriors' Fortress",
        "South of Lava Maze",
        "South of Wilderness Volcano",
        "South of Wilderness Agility Course"
      ]
  }
  
  export const locationToRegionMap = {
    // Asgarnia
    "East Falador bank": "Asgarnia",
    "West Falador mine": "Asgarnia",
    "Taverley house portal": "Asgarnia",
    "Crafting guild": "Asgarnia",
    "North Dwarven Mine entrance": "Asgarnia",
  
    // Fremennik
    "Neitiznot south of rune rock": "Fremennik",
    "Jatizso mine entrance": "Fremennik",
    "Keldagrim entrance mine": "Fremennik",
    "Rellekka mine": "Fremennik",
    "Lunar Isle mine entrance": "Fremennik",

    // Kandarin
    "Mine north-west of hunter guild": "Kandarin",
    "Port Khazard mine": "Kandarin",
    "Ardougne Monastery": "Kandarin",
    "Gnome Stronghold spirit tree": "Kandarin",
    "West of Grand Tree": "Kandarin",
    "Myths' Guild": "Kandarin",
    "Feldip Hills (aks fairy ring)": "Kandarin",
    "Rantz cave": "Kandarin",
    "Corsair Resource Area": "Kandarin",
    "Yanille bank": "Kandarin",
    "South of Legends' Guild": "Kandarin",

    // Karamja
    "Shilo Village gem mine": "Karamja",
    "Brimhaven northwest gold mine": "Karamja",
    "Nature Altar mine north of Shilo": "Karamja",
    "North Crandor": "Karamja",
    "South Crandor": "Karamja",
    "Southwest of Brimhaven Poh": "Karamja",
  
    // Desert
    "Al Kharid bank": "Desert",
    "Al Kharid mine": "Desert",
    "North of Al Kharid PvP Arena": "Desert",
    "Agility Pyramid mine": "Desert",
    "Desert Quarry mine": "Desert",

    // Misthalin
    "Varrock east bank": "Misthalin",
    "Draynor Village": "Misthalin",
    "Southeast Varrock mine": "Misthalin",
    "East Lumbridge Swamp mine": "Misthalin",
    "Champions' Guild mine": "Misthalin",
    "West Lumbridge Swamp mine": "Misthalin",
    "Rimmington mine": "Misthalin",
  
    // Morytania
    "Theatre of Blood bank": "Morytania",
    "Canifis bank": "Morytania",
    "Fossil Island Volcanic Mine entrance": "Morytania",
  
    // Tirannwn
    "Lletya": "Tirannwn",
    "Prifddinas Zalcano entrance": "Tirannwn",
  
    // Wilderness
    "Wilderness Resource Area": "Wilderness",
    "Mage Arena bank (lvl 56 Wildy)": "Wilderness",
    "Mage of Zamorak mine (lvl 7 Wildy)": "Wilderness",

    // Kourend
    "South Lovakengj bank": "Kourend",
    "Hosidius mine": "Kourend",
    "Shayzien mine south of Kourend Castle": "Kourend",
    "Port Piscarilius mine in Kourend": "Kourend",
    "Mount Karuulm mine": "Kourend",

    // Varlamore
    "Aldarin mine in Varlamore": "Varlamore",
    "Varlamore colosseum entrance bank": "Varlamore",
    "Varlamore South East mine": "Varlamore",

    "Piscatoris (akq fairy ring)": "Pisc/Gnome",

    "Mos Le'Harmless west bank": "Mos Le'Harmless",
    "Fossil Island rune rocks": "Fossil Island",

    "Chambers of Xeric bank": "Kebos",
  };
  
  
  