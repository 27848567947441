import React from 'react';
import { FaHeart } from 'react-icons/fa';
import { MdBlock } from 'react-icons/md';

const buttonClasses = "rounded-full px-4 py-2 font-semibold text-white transition-transform duration-300 transform hover:scale-110";

const WelcomeBannerFavoriteSort = () => {
  return (
    <div className="flex flex-col space-y-4">
      {/* Favorite Section */}
      <div className="bg-gray-700 p-3 rounded-lg shadow-md flex flex-col">
        <p className="text-white text-[3vw] lg:text-[2vw] font-bold">Favorite what you love</p>
        <div className="flex items-center">
          <div className="p-[1vw] flex items-center justify-center">
            <FaHeart className="cursor-pointer text-[8vw] lg:text-[4vw] text-yellow-500 hover:scale-125 transition-transform duration-300 hover:drop-shadow-[0_0_8px_rgba(255,255,0,0.8)]" />
          </div>
          <div className="ml-3">
            <p className="text-gray-300 text-[3vw] lg:text-[1.5vw] font-bold">Save stars to your favorites</p>
            <p className="text-gray-400 text-[2vw] lg:text-[1vw]">Quickly access stars you care about by clicking the heart icon.</p>
          </div>
        </div>
        <div className="flex items-center">
          <div className="p-[1vw] flex items-center justify-center">
            <MdBlock className="cursor-pointer text-[8vw] lg:text-[4vw] text-red-500 hover:scale-125 transition-transform duration-300 hover:drop-shadow-[0_0_8px_rgba(255,0,0,0.8)]" />
          </div>
          <div className="ml-3">
            <p className="text-gray-300 text-[3vw] lg:text-[1.5vw] font-bold">Filter out stars you dislike</p>
            <p className="text-gray-400 text-[2vw] lg:text-[1vw]">Remove stars from view by clicking the block icon.</p>
          </div>
        </div>
      </div>

      {/* Sort Section */}
      <div className="bg-gray-700 p-3 rounded-lg shadow-md flex flex-col">
        <p className="text-white text-[3vw] lg:text-[2vw] font-bold">Sort how you like</p>
        <div className="flex items-center">
          <div className="px-[1vw] py-[2vw] flex items-center justify-center">
            <button className={`${buttonClasses} text-[3vw] lg:text-[1vw] whitespace-nowrap bg-blue-500 hover:bg-blue-600 shadow-md`}>
              Top Tier
            </button>
          </div>
          <div className="ml-3">
            <p className="text-gray-300 text-[3vw] lg:text-[1.5vw] font-bold">Prioritize highest tiered stars</p>
            <p className="text-gray-400 text-[2vw] lg:text-[1vw]">Sort stars by efficiency to highlight the highest-tiered ones.</p>
          </div>
        </div>
        <div className="flex items-center">
          <div className="px-[1vw] py-[2vw] flex items-center justify-center">
            <button className={`${buttonClasses} text-[3vw] lg:text-[1vw] whitespace-nowrap bg-green-500 hover:bg-green-600 shadow-md`}>
              Most Recent
            </button>
          </div>
          <div className="ml-3">
            <p className="text-gray-300 text-[3vw] lg:text-[1.5vw] font-bold">See the latest stars added</p>
            <p className="text-gray-400 text-[2vw] lg:text-[1vw]">Displays stars recently added for up-to-date tracking.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBannerFavoriteSort;
