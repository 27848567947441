import React, { useState } from "react";

const StarFiltersButton = ({ filterType, activeFilters, setActiveFilters, label, onClick }) => {
  const [clicked, setClicked] = useState(false); // Track button click for `region` only

  const isActive = filterType === "region" ? clicked : activeFilters[filterType];

  const toggleFilter = () => {
    setActiveFilters((prev) => {
      if (filterType === "efficient") {
        return {
          ...prev,
          efficient: !isActive,
          mostRecent: false,
        };
      } else if (filterType === "mostRecent") {
        return {
          ...prev,
          mostRecent: !isActive,
          efficient: false,
        };
      } else if (filterType === "region") {
        setClicked((prevClicked) => !prevClicked); // Toggle `clicked` state for visual activation
        if (onClick) onClick(); // Call onClick to handle showing region filter list
        return prev; // Do not modify `activeFilters.region`
      } else {
        return {
          ...prev,
          [filterType]: !isActive,
        };
      }
    });
  };

  // Set button color based on active state and filter type
  const buttonColor = isActive
    ? filterType === "efficient"
      ? "bg-blue-600 hover:bg-blue-500"
      : filterType === "mostRecent"
      ? "bg-green-600 hover:bg-green-500"
      : filterType === "region"
      ? "bg-orange-600 hover:bg-orange-500"
      : filterType === "freeToPlay"
      ? "bg-teal-600 hover:bg-teal-500"
      : "bg-gray-600 hover:bg-gray-400"
    : "bg-gray-600 hover:bg-gray-400";

  const buttonClasses = `flex justify-center space-x-2 px-[3vw] py-[1vw] font-semibold rounded-full transition-colors ${buttonColor} text-white`;

  return (
    <button onClick={toggleFilter} className={buttonClasses}>
      <span className="text-[3vw] lg:text-[1.5vw] text-center whitespace-nowrap">{label}</span>
    </button>
  );
};

export default StarFiltersButton;
