import React, { useState, useEffect, useMemo } from "react";
import ViewModeToggle from "./ViewModeToggle";
import StarPanelList from "./StarPanelList";
import StarFilters from "./StarFilters";
import NoPreferredStars from "./NoPreferredStars";
import { colorMap, shadowColorMap } from "./starMapping"; 

const parseRelativeTime = (relativeTimeString) => {
  const now = new Date();
  const [amount, unit] = relativeTimeString.match(/\d+|\D+/g);
  const number = parseInt(amount);

  if (unit.includes("m")) return new Date(now.getTime() - number * 60000);
  if (unit.includes("h")) return new Date(now.getTime() - number * 3600000);
  if (unit.includes("d")) return new Date(now.getTime() - number * 86400000);

  return now;
};

const StarPanelContainer = ({ title, stars, preferredStars, dislikedStars, togglePreferredStar, toggleDislikedStar, ...props }) => {
  const [viewMode, setViewMode] = useState("stack");
  const [showFilters, setShowFilters] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    efficient: true,
    mostRecent: false,
    freeToPlay: false,
    maxTime: 10,
    tiers: [false, false, true, true, true, true, true, true, true],
    region: [], // Change to array to support multiple regions
    location: "",
  });

  const filteredStars = useMemo(() => {
    let result = stars;
  
    if (title === "Preferred Stars") {
      result = result.filter((star) => preferredStars.includes(star.location));
    }
  
    // Apply tier filter
    result = result.filter((star) => {
      const tier = parseInt(star.size.substring(1));
      return activeFilters.tiers[tier - 1];
    });
  
    // Apply free-to-play filter
    if (activeFilters.freeToPlay) {
      const freeToPlayWorlds = [301, 308, 316];
      result = result.filter((star) => freeToPlayWorlds.includes(parseInt(star.world)));
    }
  
    // Apply max time filter
    const now = new Date();
    const maxTimeInMillis = activeFilters.maxTime * 60000;
    result = result.filter((star) => now - parseRelativeTime(star.time) <= maxTimeInMillis);
  
    // Apply location filter
    if (activeFilters.location) {
      result = result.filter((star) => star.location === activeFilters.location);
    }
  
    // Apply region filter as an array
    if (activeFilters.region.length > 0) {
      result = result.filter((star) => activeFilters.region.includes(star.region));
    }
  
    // Apply sorting logic
    if (activeFilters.mostRecent) {
      result = result.sort((a, b) => parseRelativeTime(b.time) - parseRelativeTime(a.time));
    } else if (activeFilters.efficient) {
      result = result.sort((a, b) => parseInt(b.size.substring(1)) - parseInt(a.size.substring(1)));
    }
  
    return result;
  }, [stars, preferredStars, activeFilters, title]);

  const getGridCols = () => {
    if (viewMode === "list") return "grid-cols-2"; // Two columns for list view
    if (viewMode === "stack") return "grid-cols-2 lg:grid-cols-4";
    if (viewMode === "icons") return "grid-cols-2 sm:grid-cols-3 md:grid-cols-4";
    return "grid-cols-1";
  };

  return (
    <div id={title.split(" ")[0]} className="p-[3vw] lg:p-[1vw] rounded-lg shadow-lg bg-gray-800 bg-opacity-40 relative">
      {/* Fixed View Mode Toggle in top-right corner */}
      <div className="absolute top-4 right-4">
        <ViewModeToggle viewMode={viewMode} setViewMode={setViewMode} />
      </div>

      <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:items-center justify-between mb-4">
        {/* Title and Filter Buttons on one line on large screens */}
        <div className="flex flex-col space-y-[1vw] lg:space-y-[.25vw] lg:flex-row lg:items-center lg:space-x-4 w-full">
          <h2 className="text-2xl text-left text-[18px] lg:text-[36px] font-semibold text-white flex items-center">
            {title} 
            <span className="inline-block text-sm lg:text-lg text-gray-400 ml-2" style={{ minWidth: '30px' }}>
              ({filteredStars.length})
            </span>
          </h2>
          <StarFilters
            inline={true}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            setShowFilters={setShowFilters}
            showFilters={showFilters}
          />
        </div>
      </div>

      {/* Expanded Filters for larger screens */}
      {showFilters && (
        <StarFilters inline={false} activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
      )}

      {/* Star Panel List */}
      <div className={`grid ${getGridCols()} gap-2 transition-all duration-500 ease-in-out transform`}>
        {filteredStars.length > 0 ? (
          filteredStars.map((star, index) => {
            const tierNumber = star.size.replace("T", "");
            const sizeColorClass = colorMap[tierNumber] || "text-gray-500 border-gray-500";
            const tierShadowColor = shadowColorMap[tierNumber] || "rgba(128,128,128,0.6)";

            return (
              <div
                key={`${star.world}-${star.location}`}
                className="w-full p-[1vw] lg:p-[1vw] rounded-lg transition duration-500 ease-in-out transform opacity-100 scale-100 animate-scale-in"
              >
                <StarPanelList
                  star={star}
                  viewMode={viewMode}
                  setActiveFilters={setActiveFilters}
                  activeFilters={activeFilters}
                  preferredStars={preferredStars}
                  dislikedStars={dislikedStars}
                  togglePreferredStar={togglePreferredStar}
                  toggleDislikedStar={toggleDislikedStar}
                  sizeColorClass={sizeColorClass}
                  tierShadowColor={tierShadowColor}
                />
              </div>
            );
          })
        ) : (
          <NoPreferredStars
            message={
              title === "Preferred Stars" && preferredStars.length === 0
                ? "You have favorites, but sadly none are available."
                : title === "All Stars"
                ? "No stars available, but try a different filter."
                : "No stars favorited yet, give it a try."
            }
          />
        )}
      </div>

      <style jsx>{`
        @keyframes scale-in {
          from {
            opacity: 0;
            transform: scale(0.9);
          }
          to {
            opacity: 1;
            transform: scale(1);
          }
        }
        .animate-scale-in {
          animation: scale-in 0.5s ease-in-out forwards;
        }
      `}</style>
    </div>
  );
};

export default StarPanelContainer;
