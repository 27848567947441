// App.jsx
import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './components/common/StarBackdrop.css';
import Login from './components/auth/Login';
import SignUp from './components/auth/SignUp';
import AuthComponent from './components/auth/AuthComponent';
import PrivateRoute from './components/common/PrivateRoute';
import NavBar from './components/common/NavBar';
import StarBackdrop from './components/common/StarBackdrop';
import Dashboard from './pages/Dashboard/Dashboard';
import { AuthProvider } from './components/context/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { StarProvider } from './components/context/StarContext';
import Footer from './components/common/Footer';

function App() {
  const [user, setUser] = useState(null);
  const [isNightMode, setIsNightMode] = useState(true);
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [isWelcomeVisible, setIsWelcomeVisible] = useState(!!user); // Show if no user is logged in

  const toggleNightMode = () => {
    setIsNightMode(!isNightMode);
  };

  const toggleRefreshStars = () => {
    setRefreshToggle((prev) => !prev);
  };

  const toggleWelcome = () => {
    setIsWelcomeVisible((prev) => !prev); // Toggle welcome banner visibility
  };

  const containerStyle = {
    position: 'relative',
  };
  useEffect(() => {
    console.log("User state changed:", user);
  }, [user]);

  return (
    <Router>
      <Fragment>
        <div style={containerStyle} className={`App ${isNightMode ? 'bg-gray-900' : 'bg-gray-100'} min-h-screen w-full`}>
        <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
        <AuthProvider user={user} setUser={setUser}>
              <StarProvider>
                {/* Pass toggleWelcome to NavBar and Dashboard */}
                <NavBar toggleNightMode={toggleNightMode} toggleRefreshStars={toggleRefreshStars} toggleWelcome={toggleWelcome} />
                <AuthComponent />
                <div className="main-content w-full">
                  <Routes>
                    <Route path="/" element={<Dashboard refreshToggle={refreshToggle} toggleWelcome={toggleWelcome} isWelcomeVisible={isWelcomeVisible} />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route
                      path="/dashboard"
                      element={
                        <PrivateRoute>
                          <Dashboard refreshToggle={refreshToggle} toggleWelcome={toggleWelcome} isWelcomeVisible={isWelcomeVisible} />
                        </PrivateRoute>
                      }
                    />
                  </Routes>
                </div>
                <Footer></Footer>
              </StarProvider>
            </AuthProvider>
          </GoogleOAuthProvider>
        </div>
      </Fragment>
    </Router>
  );
}

export default App;
