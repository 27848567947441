import React, { useState, useEffect } from "react";
import { locationToRegionMap, regionColorMap } from "./starMapping";

const StarFiltersRegionButtons = ({ activeFilters, setActiveFilters, show }) => {
  const [isVisible, setIsVisible] = useState(show);
  const uniqueRegions = Array.from(new Set(Object.values(locationToRegionMap)));

  useEffect(() => {
    setIsVisible(show);
  }, [show]);

  const toggleRegion = (region) => {
    setActiveFilters((prev) => {
      const updatedRegions = new Set(prev.region || []);
      updatedRegions.has(region) ? updatedRegions.delete(region) : updatedRegions.add(region);
      return { ...prev, region: Array.from(updatedRegions) };
    });
  };

  return (
    <div
      className={`transition-all duration-700 ease-in-out pt-2 ${isVisible ? "opacity-100" : "opacity-0"}`}
      style={{ height: isVisible ? "auto" : "0px" }}
    >
      <div className="flex flex-wrap gap-2"> {/* Ensure consistent spacing with gap-2 */}
        {uniqueRegions.map((region) => {
          const isActive = activeFilters.region && activeFilters.region.includes(region);
          const regionColorClass = regionColorMap[region] || "bg-gray-500";

          return (
            <button
              key={region}
              onClick={() => toggleRegion(region)}
              className={`rounded-full px-4 py-2 transition-colors duration-300 border-2 ${
                isActive
                  ? `${regionColorClass} text-white border-current` // Active: colored background, visible border
                  : "bg-gray-600 text-white border-transparent" // Inactive: gray background, invisible border
              }`}
            >
              {region}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default StarFiltersRegionButtons;
