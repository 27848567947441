import React from "react";
import { FaTimes } from "react-icons/fa";
import { locationToRegionMap, regionColorMap } from "./starMapping";
import { useStarContext } from "../context/StarContext";

const StarPreferences = ({ onRemove }) => {
    const { preferredStarsList, dislikedStarsList } = useStarContext();

    const renderLocationButton = (location, onRemoveCallback) => {
        const regionColorClass = regionColorMap[locationToRegionMap[location]] || "bg-gray-500"; // Map region to color class

        return (
            <div
                key={location}
                className={`group cursor-pointer flex items-center justify-between border ${regionColorClass} text-white px-4 py-2 rounded-full mb-2 transition-all duration-300 hover:pr-4`}
                onClick={() => onRemoveCallback(location)} // Entire button is clickable
            >
                <span className="text-[2vw] lg:text-[1vw]">{location}</span>
                <div className="group-hover:inline hidden">
                    <FaTimes
                        className="ml-2 text-white cursor-pointer transition-all duration-300 hover:text-red-500"
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="w-full bg-gray-900 rounded-lg">
            <div className="bg-gray-800 bg-opacity-80 text-white font-bold p-6 rounded-lg shadow-lg">
                {/* Favorites Section */}
                <div className="mb-6">
                    <h2 className="text-[3vw] lg:text-[2vw] font-bold text-left mb-4">
                        Favorites
                    </h2>
                    {preferredStarsList && preferredStarsList.length > 0 ? (
                        <div className="flex flex-col">
                            {preferredStarsList.map((location) =>
                                renderLocationButton(location, (loc) =>
                                    onRemove(loc, "preferred")
                                )
                            )}
                        </div>
                    ) : (
                        <p className="text-gray-400 text-left">No favorite stars yet.</p>
                    )}
                </div>

                {/* Dislikes Section */}
                <div>
                    <h2 className="text-[3vw] lg:text-[2vw] font-bold text-left mb-4">
                        Dislikes
                    </h2>
                    {dislikedStarsList && dislikedStarsList.length > 0 ? (
                        <div className="flex flex-col">
                            {dislikedStarsList.map((location) =>
                                renderLocationButton(location, (loc) =>
                                    onRemove(loc, "disliked")
                                )
                            )}
                        </div>
                    ) : (
                        <p className="text-gray-400 text-left">No disliked stars yet.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default StarPreferences;
