import React, { useState, useEffect } from "react";
import { FaHeart } from "react-icons/fa6";
import { MdBlock } from "react-icons/md";
import { GiMining } from "react-icons/gi";
import VerticalTimerBar from "./VerticalTimerBar";
import { colorMap, shadowColorMap, locationToRegionMap, regionColorMap } from "./starMapping";

const calculateCurrentTier = (startingTier, timeLanded, interval = 360) => {
  const now = new Date();
  const landedTime = new Date(`${timeLanded} UTC`);
  const elapsedSeconds = Math.floor((now.getTime() - landedTime.getTime()) / 1000);
  const totalTiersPassed = Math.floor(elapsedSeconds / interval);
  const startingTierNum = parseInt(startingTier.replace("T", ""), 10);
  return `T${Math.max(startingTierNum - totalTiersPassed, 1)}`;
};

const getRegionForLocation = (location, region) => {
  if (location in locationToRegionMap) return locationToRegionMap[location];
  return region && region in regionColorMap ? region : "Other";
};

const StarPanelStack = ({
  star,
  setActiveFilters,
  activeFilters,
  preferredStars,
  dislikedStars,
  togglePreferredStar,
  toggleDislikedStar,
}) => {
  const [startingTier] = useState(star.size);
  const [currentTier, setCurrentTier] = useState(star.size);
  const [isRemoved, setIsRemoved] = useState(false);
  const [tierShadowColor, setTierShadowColor] = useState("")
  const [sizeColorClass, setSizeColorClass] = useState("")

  const isFavorited = preferredStars.includes(star.location);
  const isDisliked = dislikedStars.includes(star.location);
  const isMiningActive = activeFilters.location?.trim().toLowerCase() === star.location?.trim().toLowerCase();

  const region = getRegionForLocation(star.location, star.region);
  const regionColorClass = regionColorMap[region] || "bg-gray-500";

  useEffect(() => {
    const updatedTier = calculateCurrentTier(startingTier, star.time_landed);
    setCurrentTier(updatedTier);
    let updatedTierNum = parseInt(startingTier.replace("T", ""), 10);
    setTierShadowColor(shadowColorMap[updatedTierNum] || "rgba(128,128,128,0.6)");
    setSizeColorClass(colorMap[updatedTierNum] || "text-gray-500 border-gray-500");
  }, [startingTier, star.time_landed]);

  const handleTierUpdate = (newTierLevel) => {
    const startingTierNum = parseInt(startingTier.replace("T", ""), 10);
    const updatedTierNum = Math.max(newTierLevel, 1);
    setTierShadowColor(shadowColorMap[updatedTierNum] || "rgba(128,128,128,0.6)");
    setSizeColorClass(colorMap[currentTier.replace("T", "")] || "text-gray-500 border-gray-500");

    if (updatedTierNum < startingTierNum) setCurrentTier(`T${updatedTierNum}`);
    else setIsRemoved(true);
  };

  const handleMiningClick = () => {
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      location: prevFilters.location === star.location ? "" : star.location,
    }));
  };

  if (isRemoved) return null;

  return (
<div
  className="bg-gray-800 bg-opacity-80 text-white font-bold rounded-lg shadow-lg mb-2 mx-auto flex flex-col items-center transition-transform duration-300 hover:scale-105 relative"
  style={{
    boxShadow: `0 0 10px ${tierShadowColor}, 0 0 20px ${tierShadowColor}`,
    height: "20vw",
    width: "100%", // Ensures it scales based on its parent container
  }}
>
  <div className="absolute inset-0 flex flex-col space-y-[0.5vw] pt-[2.0vw] lg:p-[1vw]">
    {/* Top Row (Tier + Timer, Mining Icon, World) */}
    <div className="flex items-center justify-between w-full h-1/3">
      {/* First Column: Tier + Timer */}
      <div className="p-[1vw] lg:p-[0.25vw] flex-1 flex flex-row justify-start items-center space-x-[1vw]  transition-transform duration-300 hover:scale-125">
        {/* Tier Display */}
        <div
          className={`flex flex-col items-center justify-center aspect-square px-[1.5vw] py-[1vw] rounded-md lg:rounded-lg ${sizeColorClass}`}
          style={{
            boxShadow: `0 0 10px ${tierShadowColor}, 0 0 20px ${tierShadowColor}`,
          }}
        >
          <span className={`text-[2.5vw] lg:text-[1.75vw] font-extrabold ${sizeColorClass.replace("border", "text")}`}>
            {currentTier}
          </span>
          <span className="text-[1.5vw] lg:text-[0.75vw] lg:text-base">Tier</span>
        </div>

        {/* Vertical Timer Bar */}
        <div>
          <VerticalTimerBar
            timeLanded={star.time_landed}
            startingTier={parseInt(startingTier.replace("T", ""), 10)}
            currentTier={parseInt(currentTier.replace("T", ""), 10)}
            duration={360}
            onTierUpdate={handleTierUpdate}
          />
        </div>
      </div>

      {/* Second Column: Mining Icon */}
      <div className="flex-1 flex justify-center items-center">
        <GiMining
          className={`cursor-pointer text-[4vw] lg:text-[4vw] transition duration-300 ${
            isMiningActive ? "text-yellow-500" : "text-gray-500"
          } hover:text-yellow-300 hover:scale-125 hover:drop-shadow-[0_0_10px_rgba(255,215,0,0.8)]`}
          onClick={handleMiningClick}
        />
      </div>

      {/* Third Column: World Box */}
      <div className="p-[1vw] lg:p-[0.25vw] flex-1 flex justify-end items-center">
        <div
          className="px-[1vw] py-[.75vw] aspect-square backface-hidden rounded-md lg:rounded-lg bg-gray-700 flex flex-col items-center  shadow-[0_0_10px_rgba(0,191,255,0.6),0_0_20px_rgba(0,191,255,0.4)] transition-transform duration-300 hover:scale-125"
        >
          <span className="text-[2.5vw] lg:text-[1.75vw] font-extrabold">{star.world}</span>
          <span className="text-[1.5vw] lg:text-[0.75vw] text-gray-300">World</span>
        </div>
      </div>
    </div>

    {/* Location */}
    <span className="text-[2vw] lg:text-[1.5vw] h-1/3 py-[1vw] lg:py-[2vw] font-semibold text-gray-200">
      {star.location}
    </span>

    {/* Bottom Row (Region Button and Controls) */}
    <div className="flex justify-between items-center w-full h-1/3 pb-[1vw] lg:pb-[0vw]">
      <div className="flex-1 flex justify-start items-center p-[1vw] lg:p-[0vw]">
        {/* Region Button */}
        <button
          className={`rounded-full px-[1.5vw] py-[.75vw] ${regionColorClass} border text-[2vw] lg:text-[1.5vw] transition-transform duration-300 hover:scale-125`}
        >
          {region}
        </button>
      </div>

      <div className="flex-1 flex justify-end items-center p-[1vw] lg:p-[0vw]">
        {/* Star Controls */}
        <div className="flex space-x-[.75vw] lg:space-x-[0.75vw]">
          <FaHeart
            className={`cursor-pointer text-[5vw] lg:text-[3vw] transition-transform duration-300 hover:scale-125 ${
              isFavorited ? "text-yellow-500" : "text-gray-400"
            } hover:text-yellow-400 hover:drop-shadow-[0_0_10px_rgba(255,255,0,0.8)]`}
            onClick={() => togglePreferredStar(star)}
          />
          <MdBlock
            className={`cursor-pointer text-[5vw] lg:text-[3vw] transition-transform duration-300 hover:scale-125 ${
              isDisliked ? "text-red-500" : "text-gray-400"
            } hover:text-red-400 hover:drop-shadow-[0_0_10px_rgba(255,0,0,0.8)]`}
            onClick={() => toggleDislikedStar(star)}
          />
        </div>
      </div>
    </div>
  </div>
</div>



  );
};

export default StarPanelStack;
