import React, { useState, useEffect } from "react";

const StarCountdown = ({ toggleRefreshStars }) => {
  const [timeLeft, setTimeLeft] = useState(30);
  const [backgroundColor, setBackgroundColor] = useState("bg-blue-400");

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime === 1) {
          toggleRefreshStars(); // Trigger toggle when timer reaches 0
          return 30; // Reset timer to 30 seconds
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup timer on component unmount
  }, [toggleRefreshStars]);

  useEffect(() => {
    // Calculate the background color based on the countdown value
    const percentage = (timeLeft / 30) * 100;
    if (percentage > 75) {
      setBackgroundColor("bg-blue-400");
    } else if (percentage > 50) {
      setBackgroundColor("bg-teal-400");
    } else if (percentage > 25) {
      setBackgroundColor("bg-orange-400");
    } else {
      setBackgroundColor("bg-red-400");
    }
  }, [timeLeft]);

  return (
    <button
      className={`text-[1.5vw] lg:text-[1.5vw] w-[15vw] lg:w-[20vw] h-[5vw] lg:h-[4vw] rounded-full px-4 py-2 text-white font-semibold ${backgroundColor} transition-colors duration-1000 ease-in-out hover:bg-opacity-80 flex items-center justify-center`}
      title="Refresh Timer"
    >
      {timeLeft > 2 ? (
        <div className="flex items-center justify-center h-full">
          <span className="whitespace-nowrap">
            Refreshing in... {timeLeft}s
          </span>
        </div>
      ) : (
<div className="flex items-center justify-between h-full">
  {/* Text */}
  <span className="whitespace-nowrap text-center flex-1">Refreshing...</span>
  {/* Spinner */}
  <svg
    className="animate-spin h-[2vw] w-[2vw] text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
    ></path>
  </svg>
</div>


      )}
    </button>
  );
};

export default StarCountdown;
