import React, { useState } from "react";

const StarFiltersMaxTimeButton = ({ activeFilters, setActiveFilters }) => {
  const timeOptions = [1, 5, 10, 20, 30];
  const colors = ["bg-yellow-500", "bg-blue-500", "bg-green-500", "bg-purple-500", "bg-orange-500"];
  const [buttonColor, setButtonColor] = useState(colors[2]);

  const handleTimeCycle = () => {
    const currentIndex = timeOptions.indexOf(activeFilters.maxTime);
    const nextIndex = (currentIndex + 1) % timeOptions.length;
    const newTime = timeOptions[nextIndex];
    setActiveFilters((prev) => ({ ...prev, maxTime: newTime }));
    setButtonColor(colors[nextIndex]);
  };

  const buttonClasses = `flex justify-center space-x-2 px-[2vw] py-[1vw] font-semibold rounded-full transition-colors ${buttonColor} text-white`;

  return (
    <button onClick={handleTimeCycle} className={buttonClasses}>
      <span className="text-[3vw] lg:text-[1.5vw]">{activeFilters.maxTime}m</span>
    </button>
  );
};

export default StarFiltersMaxTimeButton;
