import React, { useState } from 'react';
import { TextField, Button, Typography, CircularProgress } from '@mui/material';
import { useAuthContext } from '../context/AuthContext';
import { GoogleLogin } from '@react-oauth/google';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebaseConfig';
import StarPreferences from '../common/StarPreferences';

const AuthComponent = () => {
    const { isAuthWindowOpen, toggleAuthWindow, loginWithToken, user, logout } = useAuthContext();
    const [isSignUp, setIsSignUp] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleSignUp = async () => {
        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match');
            return;
        }
        setIsLoading(true);
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const token = await userCredential.user.getIdToken();
            await loginWithToken(token, "firebase");
            setSuccessMessage('Sign Up successful! Redirecting...');
            setTimeout(() => toggleAuthWindow(), 3000);
            setTimeout(() => { setSuccessMessage(''); }, 3000);
        } catch (error) {
            setErrorMessage('Sign Up failed. Please try again.');
            console.error('Sign Up error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSignIn = async () => {
        setIsLoading(true);
        setErrorMessage('');
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const token = await userCredential.user.getIdToken();
            await loginWithToken(token, "firebase");
            setSuccessMessage('Successfully logged in! Redirecting...');
            setTimeout(() => toggleAuthWindow(), 3000);
            setTimeout(() => { setSuccessMessage(''); }, 3000);
        } catch (error) {
            setErrorMessage('Sign-in failed. Please check your credentials.');
            console.error('Sign-in error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGoogleSuccess = async (credentialResponse) => {
        setIsLoading(true);
        setErrorMessage('');
        try {
            const token = credentialResponse.credential;
            await loginWithToken(token, "google");
            setSuccessMessage('Successfully logged in with Google! Redirecting...');
            setTimeout(() => toggleAuthWindow(), 3000);
            setTimeout(() => { setSuccessMessage(''); }, 3000);
        } catch (error) {
            setErrorMessage('Google login failed. Please try again.');
            console.error('Google login error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    if (!isAuthWindowOpen) return null;

    return (
        <div
            className="fixed h-auto inset-0 flex items-center justify-center bg-[rgba(17,24,39,0.3)] backdrop-blur-sm z-50"
            onClick={(e) => e.currentTarget === e.target && toggleAuthWindow()}
        >
            <div className="bg-gray-800 rounded-lg p-4 shadow-xl w-[90%] sm:w-[40%] lg:w-[25%]">
                <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center space-x-2">
                        <img src="/starfetcher-192.png" alt="StarFetcher Logo" className="w-[3vw] h-[3vw] mr-2" />
                        <p className="text-white font-bold text-[4vw] lg:text-[1.75vw] ">StarFetcher</p>
                        <p className="text-white text-[3vw] lg:text-[2vw] font-semibold mx-2">|</p>
                        <p className="text-white text-[3vw] lg:text-[1vw] font-bold">{user ? 'Welcome' : isSignUp ? 'Sign Up' : 'Sign In'}</p>
                    </div>
                </div>

                {(errorMessage || successMessage) && (
                    <div style={{ color: successMessage ? 'green' : 'red', marginBottom: '1rem', transition: 'height 0.3s ease' }}>
                        <p>{errorMessage || successMessage}</p>
                    </div>
                )}

                {user ? (
                    <div className="flex flex-col space-y-3 items-center text-white">
                        <div className="">
                            <StarPreferences />
                        </div>
                        <Typography variant="h6" className="mb-4">You are logged in</Typography>
                        <Button variant="contained" color="secondary" onClick={logout} className="font-bold mb-4" sx={{ py: 1.5 }}>
                            Logout
                        </Button>
                    </div>
                ) : (
                    <div>
                        <TextField
                            label="Email Address"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            size="small"
                            InputProps={{ style: { color: 'white' } }}
                            InputLabelProps={{ style: { color: 'white' } }}
                        />
                        <TextField
                            label="Password"
                            type="password"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            size="small"
                            InputProps={{ style: { color: 'white' } }}
                            InputLabelProps={{ style: { color: 'white' } }}
                        />

                        {isSignUp && (
                            <TextField
                                label="Confirm Password"
                                type="password"
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                size="small"
                                InputProps={{ style: { color: 'white' } }}
                                InputLabelProps={{ style: { color: 'white' } }}
                            />
                        )}

                        <div className="flex justify-between mt-4 space-x-2">
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={isSignUp ? handleSignUp : handleSignIn}
                                className="w-full font-bold"
                                sx={{ py: 1.5 }}
                            >
                                {isSignUp ? 'Confirm Sign Up' : 'Sign In'}
                            </Button>
                            <Button
                                type="button"
                                variant="contained"
                                color="secondary"
                                onClick={() => setIsSignUp(!isSignUp)}
                                className="w-full font-bold"
                                sx={{ py: 1.5 }}
                            >
                                {isSignUp ? 'Back to Sign In' : 'Sign Up'}
                            </Button>
                        </div>

                        <div className="flex flex-col items-center mt-4">
                            {isLoading ? (
                                <CircularProgress color="inherit" size={30} />
                            ) : (
                                <>
                                    <Typography variant="body1" className="text-white font-bold pb-2">Or</Typography>
                                    <GoogleLogin
                                        onSuccess={handleGoogleSuccess}
                                        onError={() => {
                                            console.error("Google login failed");
                                            setErrorMessage("Google login failed.");
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AuthComponent;
