import React from 'react';
import { FaTimes, FaAngleDown } from 'react-icons/fa';

const WelcomeBannerTitle = ({ isOpen, toggleBanner }) => {
  return (
    <div className="flex items-center justify-between" style={{ minHeight: isOpen ? '1vw' : '0px' }}>
      <div className="flex items-center space-x-2 p-[.75vw]">
        {isOpen ? (
          <>
            <img src="/starfetcher-192.png" alt="StarFetcher Logo" className="w-10 h-10 lg:w-12 lg:h-12" />
            <p className="text-white text-[3vw] lg:text-[2vw] font-bold">StarFetcher</p>
            <span className="text-gray-300 text-[3vw] lg:text-[2vw] mx-2">|</span>
            <p className="text-gray-300 text-[3vw] lg:text-[1.5vw]">Welcome to StarFetcher!</p>
          </>
        ) : (
          <p className="text-gray-300 text-[2vw] lg:text-[1.25vw] whitespace-nowrap transition-opacity duration-1000 opacity-30">
            Thank you for visiting! If we have any updates, we'll notify you here.
          </p>
        )}
      </div>
      <button onClick={toggleBanner} className="rounded-full self-start text-[2vw] lg:text-[1.25vw] p-[1vw] bg-gray-600 text-white transition-transform duration-300 transform hover:scale-125">
        {isOpen ? <FaTimes/> : <FaAngleDown />}
      </button>
    </div>
  );
};

export default WelcomeBannerTitle;
