import React, { useState, useRef, useEffect } from 'react';
import { FaTimes, FaAngleDown } from 'react-icons/fa';
import WelcomeBannerTitle from './WelcomeBannerTitle';
import WelcomeBannerFavoriteSort from './WelcomeBannerFavoriteSort';
import WelcomeBannerFilters from './WelcomeBannerFilters';
import WelcomeBannerPromoMessages from './WelcomeBannerPromoMessages';

const WelcomeBanner = ({ toggleWelcome, isWelcomeVisible }) => {
    const [isOpen, setIsOpen] = useState(isWelcomeVisible); // Initialize based on prop
    const contentRef = useRef(null);
  
    useEffect(() => {
      setIsOpen(isWelcomeVisible); // Update `isOpen` whenever `isWelcomeVisible` changes
    }, [isWelcomeVisible]);
  
    // Smooth height update based on `isOpen`
    useEffect(() => {
      if (contentRef.current) {
        contentRef.current.style.height = isOpen ? `${contentRef.current.scrollHeight}px` : '0px';
      }
    }, [isOpen]);
  
    const toggleBanner = () => {
      setIsOpen((prev) => !prev);
      toggleWelcome(); // Trigger external toggle
    };
  
    return (
      <div className="w-full h-auto mb-4 bg-gray-800 bg-opacity-50 p-[1vw] rounded-lg shadow-lg text-left overflow-hidden transition-all duration-1000 ease-in-out">
        <WelcomeBannerTitle isOpen={isOpen} toggleBanner={toggleBanner} />
        <div
          ref={contentRef}
          className="transition-all duration-1000 ease-in-out overflow-hidden"
          style={{ height: isOpen ? 'auto' : '0px' }}
        >
          {isOpen && (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                <WelcomeBannerFavoriteSort />
                <WelcomeBannerFilters />
              </div>
              <WelcomeBannerPromoMessages />
            </>
          )}
        </div>
      </div>
    );
  };
  
  export default WelcomeBanner;
  