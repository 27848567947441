// data.js

export const navLinks = [
    {
        id: 1,
        title: "Your Stars",
        path: "/#Preferred",
    },
    {
        id: 2,
        title: "All Stars",
        path: "/#All",
    },
    {
        id: 3,
        title: "StarFetcher Assistant",
        path: "/#StarFetcherAssistant",
    },
];

export const preference = {
        "Draynor Village": ["Running", "Walking"],
        "Al Kharid": ["Running", "Magic Carpet"],
        "Yanille bank": ["Running", "Magic"],
        "Chambers of Xeric bank": ["Teleport", "Running"],
        "South Lovakengj bank": ["Running", "Teleport"],
        "Hosidius Mine": ["Teleport", "Running"],
        "Prifddinas Zalcano entrance": ["Teleport", "Running"],
        "Theatre of Blood bank": ["Teleport", "Running"],
        "Abandoned Mine west of Burgh": ["Running", "Horse"],
        "Rellekka": ["Teleport", "Boat"],
        "Gnome Stronghold": ["Spirit Tree", "Teleport"],
        "Piscatoris": ["Fairy Ring", "Teleport"],
        "Ardougne Monastery": ["Running", "Magic"],
        "Catherby": ["Running", "Boat"],
        "Myths' Guild": ["Teleport", "Running"],
        "Mount Karuulm": ["Teleport", "Running"],
        "Crafting Guild": ["Running", "Teleport"],
        "Mage Arena": ["Teleport", "Running"],
        "Feldip Hills": ["Fairy Ring", "Teleport"],
        "Varrock East Bank": ["Running", "Horse"],
        "South Crandor": ["Boat", "Running"],
        "Keldagrim Entrance Mine": ["Minecart", "Teleport"],
        "East Lumbridge Swamp Mine": ["Running", "Teleport"],
        "North Dwarven Mine Entrance": ["Running", "Minecart"],
        "Southeast Varrock Mine": ["Running", "Teleport"],
        "Taverley House Portal": ["Teleport", "Running"],
        "Wilderness Resource Area": ["Teleport", "Running"],
        "Pirates' Hideout": ["Boat", "Running"],
        "Arceuus Dense Essence Mine": ["Teleport", "Running"],
        "Nw of Uzer (Eagle's Eyrie)": ["Running", "Teleport"],
        "Fossil Island Rune Rocks": ["Teleport", "Boat"],
        "Corsair Cove bank (innit)": ["Running", "Boat"],
        "Soul Wars south mine": ["Running", "Teleport"],
        "Shayzien mine south of Kourend Castle": ["Teleport", "Running"],
        "Rantz Cave": ["Running", "Teleport"],
        "Mount Karuulm bank": ["Running", "Teleport"],
        "Nature Altar mine north of Shilo": ["Teleport", "Boat"],
        "Mage of Zamorak mine (lvl 7 Wildy)": ["Teleport", "Running"],
        "Isafdar runite rocks": ["Teleport", "Running"],
        "Arandar mine north of Lletya": ["Teleport", "Running"],
        "Corsair Resource Area": ["Running", "Boat"],
        "Miscellania mine (cip fairy ring)": ["Fairy Ring", "Boat"],
        "West of Grand Tree": ["Teleport", "Spirit Tree"],
        "East Falador Bank": ["Running", "Teleport"],
        "Shilo Village gem mine": ["Teleport", "Boat"],
        "Port Khazard mine": ["Boat", "Running"],
        "Gnome Stronghold spirit tree": ["Spirit Tree", "Teleport"],
        "Piscatoris (akq fairy ring)": ["Fairy Ring", "Boat"],
        "Nardah bank": ["Magic Carpet", "Teleport"],
        "Southwest of Brimhaven Poh": ["Boat", "Running"],
        "West Falador mine": ["Running", "Teleport"],
        "Desert Quarry mine": ["Running", "Teleport"],
        "Burgh de Rott bank": ["Teleport", "Running"],
        "Mine north-west of hunter guild": ["Running", "Teleport"],
        "North Crandor": ["Boat", "Teleport"],
        "South of Legends' Guild": ["Running", "Teleport"],
        "Rimmington mine": ["Running", "Teleport"],
        "Port Piscarilius mine in Kourend": ["Boat", "Teleport"],
        "Port Khazard": ["Boat", "Teleport"],
        "Fremennik Isles": ["Boat", "Teleport"],
        "Lletya": ["Running", "Teleport"],
        "Mage Training Arena": ["Teleport", "Running"],
        "TzHaar City": ["Teleport", "Running"],
        "Dorgesh-Kaan": ["Teleport", "Minecart"]
    }