import React, { useEffect, useState } from "react";
import { FaHeart } from "react-icons/fa6";
import { MdBlock } from "react-icons/md";
import VerticalTimerBar from "./VerticalTimerBar";
import { colorMap, shadowColorMap, regionColorMap, locationToRegionMap } from "./starMapping";
import { GiMining } from "react-icons/gi";

const calculateCurrentTier = (startingTier, timeLanded, interval = 360) => {
  const now = new Date();
  const landedTime = new Date(`${timeLanded} UTC`);
  const elapsedSeconds = Math.floor((now.getTime() - landedTime.getTime()) / 1000);
  const totalTiersPassed = Math.floor(elapsedSeconds / interval);
  const startingTierNum = parseInt(startingTier.replace("T", ""), 10);
  return `T${Math.max(startingTierNum - totalTiersPassed, 1)}`;
};

const getRegionForLocation = (location, region) => {
  if (location in locationToRegionMap) return locationToRegionMap[location];
  return region && region in regionColorMap ? region : "Other";
};

const StarPanelDetailed = ({ star, preferredStars, dislikedStars,  setActiveFilters,
  activeFilters }) => {
  const [currentTier, setCurrentTier] = useState(star.size);
  const [isRemoved, setIsRemoved] = useState(false);
  const [tierShadowColor, setTierShadowColor] = useState("")
  const [sizeColorClass, setSizeColorClass] = useState("")

  const isFavorited = preferredStars.includes(star.location);
  const isDisliked = dislikedStars.includes(star.location);
  const isMiningActive = activeFilters.location?.trim().toLowerCase() === star.location?.trim().toLowerCase();
  const [startingTier] = useState(star.size);

  useEffect(() => {
    const updatedTier = calculateCurrentTier(startingTier, star.time_landed);
    setCurrentTier(updatedTier);
    let updatedTierNum = parseInt(startingTier.replace("T", ""), 10);
    setTierShadowColor(shadowColorMap[updatedTierNum] || "rgba(128,128,128,0.6)");
    setSizeColorClass(colorMap[updatedTierNum] || "text-gray-500 border-gray-500");
  }, [startingTier, star.time_landed]);
  
  const handleTierUpdate = (newTierLevel) => {
    const startingTierNum = parseInt(startingTier.replace("T", ""), 10);
    const updatedTierNum = Math.max(newTierLevel, 1);
    setTierShadowColor(shadowColorMap[updatedTierNum] || "rgba(128,128,128,0.6)");
    setSizeColorClass(colorMap[currentTier.replace("T", "")] || "text-gray-500 border-gray-500");

    if (updatedTierNum < startingTierNum) setCurrentTier(`T${updatedTierNum}`);
    else setIsRemoved(true);
  };

  const handleMiningClick = () => {
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      location: prevFilters.location === star.location ? "" : star.location,
    }));
  };


  if (isRemoved) return null;

  const region = getRegionForLocation(star.location, star.region);
  const regionColorClass = regionColorMap[region] || "bg-gray-500";

  return (
    <div className="bg-gray-800 bg-opacity-80 text-white font-bold p-4 rounded-lg hover:bg-gray-700 transition flex justify-between items-center mb-2 shadow-lg transition-transform duration-300 hover:scale-105"
    style={{ boxShadow: `0 0 10px ${tierShadowColor}, 0 0 20px ${tierShadowColor}` }}
    >
      {/* World Number */}
      <div className="flex flex-col items-center justify-center mr-4 px-4 py-3 rounded-lg bg-gray-800 shadow-[0_0_10px_rgba(0,191,255,0.6),0_0_20px_rgba(0,191,255,0.4)] transition-transform duration-300 hover:scale-125">
        <span className="text-2xl font-extrabold">{star.world}</span>
        <span className="text-xs text-gray-300">World</span>
      </div>

      <div className="flex items-center space-x-4 transition-transform duration-300 hover:scale-125">
          <div
            className={`flex flex-col items-center justify-center px-4 py-3 rounded-lg ${sizeColorClass}`}
            style={{
              boxShadow: `0 0 10px ${tierShadowColor}, 0 0 20px ${tierShadowColor}`,
            }}
          >
            <span className={`text-2xl font-extrabold ${sizeColorClass.replace("border", "text")}`}>
              {currentTier}
            </span>
            <span className="text-xs">Tier</span>
          </div>

          <div className="mx-2 transition-transform duration-300 hover:scale-125">
            <VerticalTimerBar
              timeLanded={star.time_landed}
              startingTier={parseInt(startingTier.replace("T", ""), 10)}
              currentTier={parseInt(currentTier.replace("T", ""), 10)}
              duration={360}
              onTierUpdate={handleTierUpdate}
            />
          </div>
        </div>

      <GiMining
          className={`ml-6 cursor-pointer text-5xl transition duration-300 ${
            isMiningActive ? "text-yellow-500" : "text-gray-500"
          } hover:text-yellow-300 hover:scale-125 hover:drop-shadow-[0_0_10px_rgba(255,215,0,0.8)]`}
          onClick={handleMiningClick}
        />

      <span className="text-xl font-semibold text-gray-200 mr-4 pl-8">{star.location}</span>

      {/* Spacer */}
      <div className="flex-grow"></div>

{/* Region Tag and Icons */}
<div className="flex items-center space-x-4">
            <button className={`rounded-full ${regionColorClass} border px-4 py-2 text-base transition-transform duration-300 hover:scale-125`}>
              {region}
            </button>
            
            {/* Heart and Dislike Icons */}
            <div className="flex space-x-4">
              <FaHeart
                className={`cursor-pointer text-5xl transition-transform duration-300 hover:scale-125 ${
                  isFavorited ? "text-yellow-500" : "text-gray-400"
                } hover:text-yellow-400 hover:drop-shadow-[0_0_10px_rgba(255,255,0,0.8)]`}
                onClick={() => {
                  togglePreferredStar(star);
                  setIsFavorited(!isFavorited);
                }}
              />
              <MdBlock
                className={`cursor-pointer text-5xl transition-transform duration-300 hover:scale-125 ${
                  isDisliked ? "text-red-500" : "text-gray-400"
                } hover:text-red-400 hover:drop-shadow-[0_0_10px_rgba(255,0,0,0.8)]`}
                onClick={() => {
                  toggleDislikedStar(star);
                  setIsDisliked(!isDisliked);
                }}
              />
            </div>
          </div>
    </div>
  );
};

export default StarPanelDetailed;
